import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/navbar';
import FormComponent from './components/FormComponent.v4';
import OrderSummaryPage from './components/orderSummary.v3';
import OptionsPage from './components/ReshipesOptions.v3';
import ThisWeeksOrders from './components/WeeksOrder.v3';
import ProductQuantityNeeded from './components/ProductQuantity.v3';
import LoginForm from './components/LoginForm.v3';
import GetDeliveryAddresses from './components/DeliveryAddresses.v3';
import NudgeCustomers from './components/NudgeCustomers.v3';
import ProtectedPage from './components/ProtectedPage.v3';
import DeleteFoodItem from './components/DeleteItems.v3';
import AddItem from './components/AddItems.v3';
import DisplaySpecificMenu from './components/DisplaySpecificMenu.v3';
import UpdateFoodPrice from './components/PriceUpdate.v3';
import CloseOrdersForWeek from './components/CloseOrders.v3';
import OpenOrderForm from './components/OpenOrderForm.v3';
import EditOrder from './components/EditOrder.v3';
import AddToOrder from './components/EditOrder.v3';
import FormClosed from './components/FormClosed.v3';
import OrderFailed from './components/orderFailure.v3';
import CustomerInfo from './components/CustomerInfo.v3';
import AllOrders from './components/allData.v3';
import OrderSubmissionInProgress from './components/pleaseWait.v3';
import { AuthProvider, useAuth } from './components/AuthContext.v3'; // Import AuthProvider and useAuth

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<FormComponent />} />
          <Route path="/order-summary" element={<OrderSummaryPage />} />
          <Route path="/reshipes/options" element={<OptionsPage />} />
          <Route path="/reshipes/this-weeks-orders" element={<ThisWeeksOrders />} />
          <Route path="/reshipes/product-quantity-needed" element={<ProductQuantityNeeded />} />
          <Route path="/reshipes/delivery-addresses" element={<GetDeliveryAddresses />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/reshipes/nudge-customers" element={<NudgeCustomers />} />
          <Route path="/reshipes/add-items" element={<AddItem />} />
          <Route path="/reshipes/delete-items" element={<DeleteFoodItem />} />
          <Route path="/reshipes/update-price" element={<UpdateFoodPrice />} />
          <Route path="/reshipes/display-menu" element={<DisplaySpecificMenu />} />
          <Route path="/reshipes/close-orders" element={<CloseOrdersForWeek />} />
          <Route path="/reshipes/open-orders" element={<OpenOrderForm />} />
          <Route path="/reshipes/edit-order" element={<AddToOrder />} />
          <Route path="/reshipes/customer-info" element={<CustomerInfo />} />
          <Route path="/form-closed" element={<FormClosed />} />
          <Route path="/order-failed" element={<OrderFailed />} />
          <Route path="/reshipes/all-orders" element={<AllOrders />} />
          <Route path="/order-submission-in-progress" element={<OrderSubmissionInProgress />} />
          <Route
            path="/protected"
            element={
              <PrivateRoute>
                <ProtectedPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
