import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FormComponent.v3.css';

const BakeryOrderForm = () => {
  const [products, setProducts] = useState([]);
  const [order, setOrder] = useState([]);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address1: '',
    aptNumber: '',
    city: '',
    state: '',
    zip: '',
    otherState: ''
  });
  const [comments, setComments] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [formOpen, setFormOpen] = useState(null);
  const [emailError, setEmailError] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true); // New state for email validity
  const navigate = useNavigate();

  useEffect(() => {
    const checkFormOpenStatus = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/item/isFormOpen');
        if (!response.ok) {
          throw new Error('Failed to check form status');
        }
        const data = await response.json();
        setFormOpen(data);
      } catch (error) {
        console.error('Error checking form status:', error);
      }
    };

    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.reshipesbakery.com/item/getItemsWithCurrentlySetMenu');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        console.log('Fetched products:', data);
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    checkFormOpenStatus();
    fetchProducts();
  }, []);

  useEffect(() => {
    if (formOpen === false) {
      navigate('/form-closed');
    }
  }, [formOpen, navigate]);

  const handleCheckboxChange = (id, isChecked) => {
    setOrder(prevOrder => {
      if (isChecked) {
        const defaultQuantity = (id === 13) ? 6 : 1; // Set default quantity to 6 for mini rolls (id 13)
        return [...prevOrder, { id, quantity: defaultQuantity }];
      } else {
        return prevOrder.filter(item => item.id !== id);
      }
    });
  };

  const handleQuantityChange = (id, quantity) => {
    setOrder(prevOrder =>
      prevOrder.map(item =>
        item.id === id
          ? { ...item, quantity: parseInt(quantity, 10) } // Ensure quantity is an integer
          : item
      )
    );
  };
  const handleCustomerInfoChange = e => {
    const { name, value } = e.target;
    setCustomerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));

    // Validate email in real-time
    if (name === 'email') {
      if (validateEmail(value)) {
        setIsEmailValid(true);
        setEmailError('');
      } else {
        setIsEmailValid(false);
      }
    }
  };

  const handleCommentsChange = e => {
    setComments(e.target.value);
  };

  const validateEmail = (email) => {
    const allowedDomains = ['aol.com', 'gmail.com', 'hotmail.com', 'yahoo.com'];
    const domain = email.split('@')[1];
    return allowedDomains.includes(domain);
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateEmail(customerInfo.email)) {
      setEmailError('Email must be from aol.com, gmail.com, hotmail.com, or yahoo.com');
      setIsEmailValid(false);
      return;
    }

    setEmailError('');
    setSubmitted(true);
    const totalData = calculateTotal();

    // Prepare data for API request
    const requestData = {
      customerInfo: {
        name: customerInfo.name,
        email: customerInfo.email,
        phoneNumber: customerInfo.phone,
        streetAddress: customerInfo.address1,
        aptNumber: customerInfo.aptNumber,
        city: customerInfo.city,
        state: customerInfo.state,
        zipCode: customerInfo.zip
      },
      orderItems: order.map(item => ({
        quantity: item.quantity,
        itemID: item.id
      })),
      comments: comments
    };

    // Redirect to "Please wait" page
    navigate('/order-submission-in-progress');

    // Post data to API
    try {
      const response = await fetch('https://api.reshipesbakery.com/order/add', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        throw new Error('Failed to submit order.');
      }

      // Redirect to order summary page upon successful submission
      navigate('/order-summary', { state: { order, products, customerInfo, comments, ...totalData } });
    } catch (error) {
      console.error('Error submitting order:', error);
      // Handle error, show a message to the user, etc.
    }
  };

  const calculateTotal = () => {
    let total = order.reduce((acc, item) => {
      const product = products.find(p => p.id === item.id);
      return acc + product.price * item.quantity;
    }, 0);

    let deliveryFee = 0;
    if (customerInfo.state === 'NY' || customerInfo.state === 'Other') {
      deliveryFee = 5;
    }

    total += deliveryFee;

    return {
      total: total.toFixed(2),
      deliveryFee: deliveryFee.toFixed(2)
    };
  };

  const groupedProducts = products.reduce((groups, product) => {
    const category = product.category || 'Uncategorized';
    if (!groups[category]) {
      groups[category] = [];
    }
    groups[category].push(product);
    return groups;
  }, {});

  if (formOpen === null) {
    return <div>Loading...</div>;
  }

  if (formOpen === false) {
    return null;
  }

  return (
    <div className="container mt-5">
      <div className="text-center">
        <img
          src="https://reshipes-logo.s3.amazonaws.com/reshipesLogo.jpg"
          alt="Reshipes Logo"
          className="form-logo"
        />
        <h1 className="mb-4">Reshipes Order Form</h1>
        <p>Contact Reshi at (201)906-7541 or reshipesbakery@gmail.com with any questions or concerns</p>
        <p7>For all special orders (simcha orders or large quantities) please reach out to Reshi</p7>
        <br></br>
      </div>
      <form onSubmit={handleSubmit} className="bg-light p-4 rounded shadow-sm">
        <h3>Customer Information</h3>
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              className="form-control"
              id="name"
              name="name"
              value={customerInfo.name}
              onChange={handleCustomerInfoChange}
              required
            />
          </div>
          <div className="form-group col-md-4">
          <label htmlFor="email">
            Email <span className="small-text">(aol, gmail, hotmail, and yahoo only)</span>
          </label>
          <input
            type="email"
            className={`form-control ${!isEmailValid ? 'invalid-email' : ''}`}
            id="email"
            name="email"
            value={customerInfo.email}
            onChange={handleCustomerInfoChange}
            required
          />
          {emailError && <small className="text-danger">{emailError}</small>}
        </div>

          <div className="form-group col-md-4">
            <label htmlFor="phone">Phone</label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              name="phone"
              value={customerInfo.phone}
              onChange={handleCustomerInfoChange}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="address1">Street Address 1</label>
            <input
              type="text"
              className="form-control"
              id="address1"
              name="address1"
              value={customerInfo.address1}
              onChange={handleCustomerInfoChange}
              required
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="aptNumber">Apartment Number</label>
            <input
              type="text"
              className="form-control"
              id="aptNumber"
              name="aptNumber"
              value={customerInfo.aptNumber}
              onChange={handleCustomerInfoChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-4">
            <label htmlFor="city">City</label>
            <input
              type="text"
              className="form-control"
              id="city"
              name="city"
              value={customerInfo.city}
              onChange={handleCustomerInfoChange}
              required
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="state">State</label>
            <select
              className="form-control"
              id="state"
              name="state"
              value={customerInfo.state}
              onChange={handleCustomerInfoChange}
              required
            >
              <option value="">Select a state</option>
              <option value="NY">New York</option>
              <option value="NJ">New Jersey</option>
              <option value="Other">Other</option>
            </select>
            {customerInfo.state === 'Other' && (
              <input
                type="text"
                className="form-control mt-2"
                id="otherState"
                name="otherState"
                placeholder="Please specify"
                value={customerInfo.otherState}
                onChange={handleCustomerInfoChange}
                required
              />
            )}
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="zip">Zip Code</label>
            <input
              type="text"
              className="form-control"
              id="zip"
              name="zip"
              value={customerInfo.zip}
              onChange={handleCustomerInfoChange}
              required
            />
          </div>
        </div>
        {products.length === 0 ? (
  <p>Loading products...</p>
) : (
  <div className="categories-grid">
    {Object.keys(groupedProducts).map(category => (
      <div key={category} className="category-column">
        <h3 className="mt-4">{category}</h3>
        {groupedProducts[category].map(product => (
          <div key={product.id} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`product-checkbox-${product.id}`}
              onChange={e =>
                handleCheckboxChange(product.id, e.target.checked)
              }
            />
            <label
              className="form-check-label"
              htmlFor={`product-checkbox-${product.id}`}
            >
              {product.size} {product.foodName} (${product.price.toFixed(2)})
            </label>
            {order.find(item => item.id === product.id) && (
              <select
              className="form-control mt-2"
              id={`product-${product.id}`}
              name={`product-${product.id}`}
              onChange={e =>
                handleQuantityChange(
                  product.id,
                  parseInt(e.target.value)
                )
              }
            >
              {(product.id === 87 || product.id === 16 || product.id === 14 || product.id === 20) 
                ? (
                  // For IDs 87, 16, 18, and 20: Display options from 1 to 30
                  [...Array(35).keys()].map(num => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))
                ) : product.id === 13 
                ? (
                  // For ID 13: Display options from 6 to 30
                  [...Array(30).keys()].map(num => (
                    <option key={num + 6} value={num + 6}>
                      {num + 6}
                    </option>
                  ))
                ) : (
                  // For all other IDs: Display options from 1 to 10
                  [...Array(10).keys()].map(num => (
                    <option key={num + 1} value={num + 1}>
                      {num + 1}
                    </option>
                  ))
                )}
            </select>
            
            
              
            )}
          </div>
        ))}
      </div>
    ))}
  </div>
)}

        <div className="form-group mt-4">
          <label htmlFor="comments">Comments:</label>
          <textarea
            className="form-control"
            id="comments"
            name="comments"
            value={comments}
            onChange={handleCommentsChange}
          />
        </div>
        <div>
          <h8> Venmo (@Bryann-isaacs) and Zelle (Bryann isaacs) are the accepted forms of payment</h8>
        </div>
        <div className="mt-4 p-4 bg-light rounded shadow-sm">
          <h4>Order Summary</h4>
          {order.map(item => {
            const product = products.find(p => p.id === item.id);
            return (
              <p key={item.id}>
                {product.size} {product.foodName} x {item.quantity} = ${(product.price * item.quantity).toFixed(2)}
              </p>
            );
          })}
          {calculateTotal().deliveryFee > 0 && (
            <p>
              <>Delivery Fee = </>${calculateTotal().deliveryFee}
            </p>
          )}
          <p>
            <strong>Total: </strong>${calculateTotal().total}
          </p>
        </div>

        <button type="submit" className="btn btn-primary mt-3">
          Submit Order
        </button>
      </form>
    </div>
  );
};

export default BakeryOrderForm;
